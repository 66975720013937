<template>
  <div>
    <div>
      <div class="mainTitle">平台核心功能</div>
      <img src="@/assets/productcore/shuju1.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">复杂管理报表场景
</div>
      <img src="@/assets/productcore/shuju2.png" alt="" />
    </div>
            <div>
      <div class="mainTitle">自助式BI场景

</div>
      <img src="@/assets/productcore/shuju3.png" alt="" />
    </div>
                <div>
      <div class="mainTitle">可视化大屏场景

</div>
      <img src="@/assets/productcore/shuju4.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "data-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
img {
  width: 86%;
}
</style>